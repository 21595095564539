import React, { useEffect, useState } from 'react';
import { getTasks, createTask, updateTask, deleteTask } from './apis';
import Task from './Task';
import { Box,Input,Button,Text } from "@chakra-ui/react";
import { IoIosAddCircle } from "react-icons/io";
import "./todo.css"

const TaskApp = () => {
    const [tasks, setTasks] = useState([]);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    useEffect(() => {
        fetchTasks();
      }, []);

    const fetchTasks = async () => {
    const data = await getTasks();
    setTasks(data);
    };
    
    const handleCreate = async () => {
        const newTask = { title, description};
        const createdTask = await createTask(newTask);
        setTasks([...tasks, createdTask]);
        setTitle('');
        setDescription('');
    };

    const handleUpdate = async (id, updatedTask) => {
        const task = await updateTask(id, updatedTask);
        setTasks(tasks.map(t => (t.id === id ? task : t)));
    };

    const handleDelete = async (id) => {
        await deleteTask(id);
        setTasks(tasks.filter(t => t.id !== id));
    };

    return (
        <Box mt={100} w="50%" mx="auto" backgroundColor="rgba(255, 255, 255, 0.8)">
          <Text mb="8px" color="#f36584" fontWeight={300} fontSize={30}>
             Add Tasks
            </Text>
          <Box as="form" mb={10}>
            <Input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Task Title"
              mb={3}
            />
            <Input
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Description"
            />
            
            <Button
              type="submit"
              colorScheme="teal"
              size="sm"
              mt={2}
              _hover={{
                background: "teal.200",
                color: "teal",
              }}
              onClick={handleCreate}
            >
              Add
              <span style={{ marginLeft: "8px" }}>
                <IoIosAddCircle color="white" fontSize={20} />
              </span>
            </Button>
          </Box>
          <Text color="teal">Task Lists</Text>
          <hr />
          <Box mt={4}>
            {tasks.map(task => (
              <Task key={task.id} task={task} onDelete={handleDelete} onUpdate={handleUpdate} />
            ))}
          </Box>
        </Box>
      );
}

export default TaskApp;
import React from "react";
import { GiCheckMark } from "react-icons/gi";
import { IoIosDoneAll } from "react-icons/io";
import { Box, IconButton, Button, Text,Flex } from "@chakra-ui/react";
import { MdDeleteOutline } from "react-icons/md";

const Task = ({ task, onDelete, onUpdate }) => {
  const handleUpdate = () => {
    const updatedTask = { ...task, is_completed: !task.is_completed };
    onUpdate(task.id, updatedTask);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      //timeZoneName: "short",
    }).format(date);
  };

  return (
    <Box border="1px" borderColor="tomato" mb={3} borderRadius="lg" p={5} backgroundColor="teal.50">
      <Text fontSize="2xl" fontWeight={200} color="#f36584" cursor="pointer">
        {task.title}
      </Text>
      <Text fontSize="lg" fontWeight={100}>{task.description}</Text>
      <Flex justifyContent="flex-end">
      
      <Button onClick={handleUpdate} variant="ghost">
        {task.is_completed ? ( 
          <IconButton
            colorScheme="blue"
            aria-label="task complete"
            icon={<IoIosDoneAll color="green" fontSize={30} />}
          />
        ) : ( 
          <IconButton
            variant="ghost"
            aria-label="task not complete"
            icon={<GiCheckMark color="green" fontSize={30} />}
          />
        )}
      </Button>
      <Button onClick={() => onDelete(task.id)} variant="ghost" mt={4}>
        <MdDeleteOutline color="#f36584" fontSize={40} />
      </Button>
  
      </Flex>
      <Text fontSize={10} color="gray.500" mb={2}>
        added on {formatDate(task.created_at)} | updated on {formatDate(task.updated_at)}
      </Text>
    </Box>
  );
};

export default Task;

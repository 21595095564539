import React, { useState } from "react";
import { useAuth } from "../Contexts/AuthContext";
import { Box, Input, Button, Heading, Text, Link } from '@chakra-ui/react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';


const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(username, password);
      navigate('/');
      window.location.reload(); 
    } catch (error) {
      console.error("Failed to login", error);
    }
  };
  
  return (
    <Box>
      <Box as="form" maxW="xl" p={10} onSubmit={handleSubmit} mt={100} mx="auto" borderWidth={2} borderRadius="lg" boxShadow="lg">
        <Heading mb={6} color="#f36584">Login</Heading>
          <Input 
            placeholder="Username"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            mb={3}
          />
          <Input
            placeholder="Password"
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            mb={6}
          />
          <Button type="submit" colorScheme="teal" _hover={{
          background: "teal.200",
          color: "teal",
        }}>Login
          </Button>
          <Text mt={5} fontSize="s" >
          Not registered?{'  '}
          <Link as={RouterLink} to="/register" color="teal.500">
            Click to register
          </Link>
        </Text>
      </Box>
    </Box>
  );
};


export default Login;

import React, { useEffect } from 'react';
import {
  Box,
  Flex,
  Heading,
  Link,
  Spacer,
  Modal,
  Button,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import { useAuth } from "../Contexts/AuthContext";
import { Link as RouterLink, useNavigate } from "react-router-dom";

const Navbar = (auth) => {
  const { user, logout, loggedUser,isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
 


  // Effect to log user state changes
  useEffect(() => {
    loggedUser();
  }, [loggedUser]);

  const handleLogout = () => {
    logout(navigate);
  };
  return (
    <Box
      as="header"
      position="fixed"
      top={0}
      padding="3"
      width="100%"
      zIndex={1000}
     // boxShadow="xs"
      bgGradient="linear(to-b, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.5))"
    >
      <Flex align="center" justify="space-between">
        <Heading size="lg" color="teal" fontWeight={150}>
          <Link as={RouterLink} to="/" _hover={{ textDecoration: "none" }}>
            JEREMIAH
          </Link>
        </Heading>
        <Spacer />

        {isAuthenticated ? (
          <>
            <Link
              mx={2}
              color="teal"
              p={2}
              borderRadius="md"
              _hover={{ bg: "teal.200", textDecoration: "none" }}
              _active={{ bg: "teal.300" }}
            >
              Welcome {user}
            </Link>
            <Link
              mx={2}
              color="teal"
              onClick={handleLogout}
              p={2}
              borderRadius="md"
              _hover={{ bg: "teal.200", textDecoration: "none" }}
              _active={{ bg: "teal.300" }}
            >
              Logout
            </Link>
          </>
        ) : (
          <>
            <Link
              href="/login"
              mx={2}
              color="teal"
              p={2}
              borderRadius="md"
              _hover={{ bg: "teal.200", textDecoration: "none" }}
              _active={{ bg: "teal.300" }}
            >
              Login
            </Link>
            <Link
              href="#"
              mx={2}
              color="teal"
              p={2}
              onClick={onOpen}
              borderRadius="md"
              _hover={{ bg: "teal.200", textDecoration: "none" }}
              _active={{ bg: "teal.300" }}
            >
              Contact
            </Link>
          </>
        )}
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Let's Contact You</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4}>
              <FormControl id="name">
                <FormLabel>Name</FormLabel>
                <Input placeholder="Enter your name" />
              </FormControl>

              <FormControl id="address">
                <FormLabel>Address</FormLabel>
                <Input placeholder="Enter your address" />
              </FormControl>

              <FormControl id="email">
                <FormLabel>Email</FormLabel>
                <Input type="email" placeholder="Enter your email" />
              </FormControl>

              <FormControl id="phone">
                <FormLabel>Phone</FormLabel>
                <Input type="tel" placeholder="Enter your phone number" />
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" type="submit" onClick={onClose}>
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Navbar;

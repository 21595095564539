import React, { useState } from 'react';
import axios from 'axios';
import { Box, Button,Input } from "@chakra-ui/react";
import API_URLS from '../../config';

const ImageUpload = ({ onUpload }) => {
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      try {
        await axios.post(`${API_URLS.image}/uploadimage/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        onUpload();
      } catch (error) {
        console.error('Image upload failed', error);
      }
    }
  };

  return (
    <Box>
      <Input type="file" onChange={handleFileChange} accept="image/*" width="100%"/>
      <Button onClick={handleUpload} mb={1}>Upload Image</Button>
    </Box>
  );
};

export default ImageUpload;

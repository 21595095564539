import React, { useState,useEffect } from "react";
import { useBlog } from '../../Contexts/BlogContext';
import BlogPost from './BlogPost';
import AddPost from './AddPost';
import { Box,Text } from "@chakra-ui/react";


const BlogApp = () => {
    const { posts, get_posts, get_comments } = useBlog();
    const [expandedPostId, setExpandedPostId] = useState(null);

    useEffect(() => {
        get_posts();
        get_comments();
    }, []);
//[get_posts, get_comments]
    const handleToggleExpand = (postId) => {
        setExpandedPostId(prevPostId => prevPostId === postId ? null : postId);
    };

    return (
        <
      >
        <Box w="80%" backgroundColor="rgba(255, 255, 255, 0.8)" mx="auto">
            <AddPost />
            {Array.isArray(posts) && posts.length > 0 ? (posts.map(post => (
                <BlogPost key={post.id} post={post} 
                isExpanded={expandedPostId === post.id}
                onToggleExpand={() => handleToggleExpand(post.id)}
                />)
            )):(
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  pt={50}
                >
                  <Text color="teal" fontWeight={150} font fontSize="3xl">
                    Nothing has been posted yet!
                  </Text>
                </Box>
              )}
        </Box>
        </>
    );
};

export default BlogApp;

import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import Navbar from './NavBar';
import Footer from './Footer';
import background from '../assets/Auth/background.jpg';

const Layout = ({ children }) => {
  const location = useLocation();
  const isAuthPage = location.pathname === '/login' || location.pathname === '/register';

  return (
    <Box
      minHeight="100vh"
      backgroundImage={`url(${background})`}
      backgroundSize="cover"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      backgroundAttachment="fixed"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Navbar />
      <Box
        as="main"
        flex="1"
        width="6xl"
        marginTop="150px" 
        overflowY="auto"
        justifyContent="center"
        mb={100}
        p={{ base: 4, md: 8, lg: 12 }} 
      w={{ base: '90%', md: '80%', lg: '70%' }} 
      maxW="1200px" 
      >
        {children}
      </Box>
      {!isAuthPage && (
        <Box
          as="footer"
          padding="4"
          backgroundColor="rgba(255, 255, 255, 0.8)"
          textAlign="center"
          width="100%"
        >
          <Footer />
        </Box>
      )}
    </Box>
  );
};

export default Layout;

import React, { useState } from 'react';
import { useBlog } from '../../Contexts/BlogContext';
import { MdSend } from "react-icons/md";
import { Box, Input, Button, Text, Textarea, Divider } from "@chakra-ui/react";
const AddPost = () => {
    const { create_post } = useBlog();
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');

    const handleAddPost = (e) => {
        e.preventDefault();
        create_post(title, content);
        setTitle('');
        setContent('');
    };

    return (
            <Box as="form" onSubmit={handleAddPost} mt={100}>
            <Text mb="8px" color="#f36584" fontWeight={300} fontSize={30}>
            New Blog Post
            </Text>
            <Input
              type="text"
              placeholder="Title of the blog"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              mb={3}
            />
            <Textarea
              value={content}
              placeholder="Write your blog post here..."
              size="sm"
              onChange={(e) => setContent(e.target.value)}
            />

            <Button
              type="submit"
              colorScheme="teal"
              size="sm"
              mt={2}
              _hover={{
                background: "teal.200",
                color: "teal",
              }}
            >
              Submit
              <span style={{ marginLeft: "8px" }}>
                <MdSend color="white" fontSize={20} />
              </span>
            </Button>
            <Box mt={10}>
            <Text fontWeight={200} color="teal" fontSize="xl">
            Blog Feed
          </Text>
          </Box>
          <Divider />
          </Box>
        
    );
};

export default AddPost;

import React from "react";
//import Footer from './Page/Footer.js';
import {
  Box,
  Button,
  Heading,
  Text,
  Flex,
  Input,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Image
//  SimpleGrid,
} from "@chakra-ui/react";

import { SearchIcon } from "@chakra-ui/icons";
import { BsFillInfoCircleFill } from "react-icons/bs"; 
import balanceImage from "./assets/Auth/balance.png";
import jeremy from "./assets/Auth/jeremy.jpeg";
import SampleGrid from "./Page/SampleGrid.js";


const Home = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box>
        
          <Flex
            justify="center"
            align="center"
            direction="column"
            textAlign="center"
          >
            <Heading as="h1" size="3xl" mb={4} maxW="4xl" fontWeight={800}>
              Discover the Next Big Thing in <Text as="span" color="#f36584">Apps</Text>{" "}
              <Text as="span" color="teal.500">
                Where Innovation Meets Excellence
              </Text>
            </Heading>
            <Text fontSize="xl" color="gray.500" mb={8} maxW="4xl">
              Where Innovation Meets Excellence. Dive into a world where
              groundbreaking technology and superior design converge, bringing
              you the most advanced and impactful apps on the market. Stay ahead
              of the curve with me.
            </Text>
            <Flex mb={8}>
              <Button colorScheme="teal" size="lg" mr={4}>
                Get Started
              </Button>
              <Button
                variant="outline"
                borderColor="teal.200"
                size="lg"
                leftIcon={<BsFillInfoCircleFill />}
                onClick={onOpen}
              >
                About
              </Button>
            </Flex>
            <Flex align="center" mb={8}>
              <Input placeholder="Search Stuffs" mt={3} size="lg" />
              <IconButton
                aria-label="Search Stuffs"
                icon={<SearchIcon />}
                size="lg"
                
              />
            </Flex>
            <Box>
              <Image
                src={balanceImage}
                alt="Chakra UI Logo"
                width="700px"
                height="auto"
                mb={8}
              />
            </Box>
          </Flex>
          <Text fontSize={50} fontWeight="hairline" color="teal">Apps</Text>
          <hr />
          <br />
          <SampleGrid />
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>About</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Text>
                  <Box mb={8}>
                    <Image
                      src={jeremy}
                      alt="Example"
                      borderRadius="full"
                      boxSize="150px"
                      objectFit="cover"
                    />
                  </Box>
                  I’m Jeremiah, a passionate technical enthusiast who loves
                  exploring the latest innovations and technology. From coding
                  to experimenting with emerging technologies, I’m driven by a
                  curiosity to understand and advance the digital world and
                  security. Join me as I delve into the exciting possibilities
                  and transformative power of technology.
                </Text>
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="teal" mr={3} onClick={onClose}>
                  Close
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
     
      </Box>
  );
};

export default Home;

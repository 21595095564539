import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Button,Spacer, Text, ButtonGroup } from "@chakra-ui/react";
import API_URLS from '../../config';

const FileList = ({ refresh }) => {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    fetchFiles();
  }, [refresh]);

  const fetchFiles = async () => {
    try {
      const response = await axios.get(`${API_URLS.file}/files/`);
      setFiles(response.data);
    } catch (error) {
      console.error('Error fetching files', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${API_URLS.file}/deletefile/${id}`);
      fetchFiles();
    } catch (error) {
      console.error('Error deleting file', error);
    }
  };

  const fetchFileContent = async (fileId) => {
    try {
      const response = await axios.get(`${API_URLS.file}/files/${fileId}`);
      const content = response.data.content;
      const blob = new Blob([new Uint8Array(content)], { type: 'application/octet-stream' });
      return URL.createObjectURL(blob);
    } catch (error) {
      console.error('Error fetching file content', error);
    }
  };

  return (
    <Box>

        {Array.isArray(files) && files.length > 0 ? (
          files.map((file) => (
            <Box key={file.id}>
              <Text fontSize="3xl" color="teal.500" fontWeight={300}>{file.filename}</Text>
              <Spacer />
              <ButtonGroup gap={2}>
              <Button
                as="a" 
                href={fetchFileContent(file.id)}
                download={file.filename}
                color="green"
                mt={3}
                variant='outline'
              >
                Download
              </Button>
              <Button onClick={() => handleDelete(file.id)} color="red" variant='ghost'>Delete</Button>
              </ButtonGroup>
            </Box>
          ))
        ) : (
          <Text color="teal" fontWeight={300}>No files found</Text>
        )}
    </Box>
  );
};

export default FileList;

import React, { useState } from 'react';
import { useAuth } from '../Contexts/AuthContext';
import { Box, Input, Button, Heading, Text, Link } from "@chakra-ui/react";
import { Link as RouterLink, useNavigate } from "react-router-dom";


const Register = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { register } = useAuth();
    const navigate = useNavigate();
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        await register(username,email,password);
        navigate('/login');
      } catch (error) {
        console.error('Failed to register', error);
      }
    };
  
    return (
      <>
      <Box as="form" onSubmit={handleSubmit} maxW="xl"
        p={10}
        mt={100}
        mx="auto"
        borderWidth={2}
        borderRadius="lg"
        boxShadow="lg">
        <Heading mb={6} color="#f36584">
          Register
        </Heading>
        
          <Input
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />

          <Input
            type='email'
            id="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            my={3}
          />

          <Input
            placeholder="Password"
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            mb={6}
          />
          <Button
          colorScheme="teal"
          _hover={{
            background: "teal.200",
            color: "teal",
          }}
          onClick={handleSubmit}
        >
          Register
        </Button>
        <Text mt={5} fontSize="s">
          Already have an account?{"  "}
          <Link as={RouterLink} to="/login" color="teal.500">
             Click here to login
          </Link>
        </Text>
      </Box>
    </>
    );
  };
  
  export default Register;
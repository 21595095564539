import React, { createContext, useState, useContext } from 'react';
import axios from 'axios';
import API_URLS from '../config';

// Create context
const AuthContext = createContext();

// Custom hook to use authentication context
export const useAuth = () => {
  return useContext(AuthContext);
};

// AuthProvider component
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('access_token'));
 // const [background, setBackground] = useState('./assets/Auth/background.jpg');
  const tokes = localStorage.getItem('access_token')
  

  // Login function
  const login = async (username, password) => {
    try {
      // Make a POST request to login
      const response = await axios.post(`${API_URLS.auth}/login`, { username, password });
      const { access_token } = response.data;

      // Store token in local storage
      localStorage.setItem('access_token', access_token);
      setIsAuthenticated(true);
      // Set authorization header
      
    } catch (error) {
      console.error('Failed to login', error);
    }
  };

  //user info
  const loggedUser = async () => {
    const headers = {
      "accept": "application/json",
      "Authorization": `Bearer ${tokes}`
    };
    try{
      // Fetch additional user details
      const getUserResponse = await axios.get(`${API_URLS.auth}/user/me`, { headers });
      const { username:fetchedUsername } = getUserResponse.data;

      // Update user state with fetched details
      setUser(fetchedUsername);
    }
    catch (error) {
      console.error('Failed to get user credentials', error);
    }
  }
  
  // Register function
  const register = async (username, email, password) => {
    try {
      await axios.post(`${API_URLS.auth}/register`, { username, email, password });
      //`${process.env.REACT_APP_API_URL_A}/register`
    } catch (error) {
      console.error('Failed to register', error);
    }
  };

  // Logout function
  const logout = () => {
    localStorage.removeItem('access_token');
    setIsAuthenticated(false);
    setUser(null);
    console.log('Logged out and token removed');
  };
  

  // Provide context values to children components
  return (
    <AuthContext.Provider value={{ user, login, register, logout,loggedUser, isAuthenticated}}>
      {children}
    </AuthContext.Provider>
  );
};

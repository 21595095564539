import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import { AuthProvider,useAuth } from './Contexts/AuthContext';
import { BlogProvider } from './Contexts/BlogContext';
import Login from './Auth/Login';
import Register from './Auth/Register';
import Logout from './Auth/Logout';
import Home from './Homepage';
import BlogApp from './MyApps/BlogApp/BlogApp';
import TaskApp from './MyApps/TodoApp/TaskApp';
import ImageApp from './MyApps/ImageApp/ImageApp';
import FileApp from './MyApps/FileApp/FileApp';
import Layout from './Page/Layout.js';

const ProtectedRoute = ({ element: Component, ...rest }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? <Component {...rest} /> : <Navigate to="/login" />;
};

const App = () => {
  return (
    <AuthProvider>
      <BlogProvider>
        <Router>
          <Layout>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/" element={<ProtectedRoute element={Home} />} />
              <Route path="/blog" element={<ProtectedRoute element={BlogApp} />} />
              <Route path="/tasks" element={<ProtectedRoute element={TaskApp} />} />
              <Route path="/images" element={<ImageApp />} />
              <Route path="/files" element={<FileApp />} />
            </Routes>
          </Layout>
        </Router>
      </BlogProvider>
    </AuthProvider>
  );
};

export default App;

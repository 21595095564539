import React, { useState } from 'react';
import axios from 'axios';
import { Box, Button,Input } from "@chakra-ui/react";

const FileUpload = ({ onUpload }) => {
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (file) {
      const formData = new FormData();
      const headers = {
        "accept": "application/json",
        'Content-Type': 'multipart/form-data'
      };
      formData.append('file', file);

      try {
        await axios.post(`${process.env.REACT_APP_API_URL_F}/uploadfile/`, formData,{ headers });
        onUpload(); 
      } catch (error) {
        console.error('File upload failed', error);
      }
    }
  };

  return (
    <Box>
      <Input type="file" onChange={handleFileChange} />
      <Button onClick={handleUpload}>Upload</Button>
    </Box>
  );
};

export default FileUpload;

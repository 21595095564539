import React, { useEffect } from "react";
import { useAuth } from "../Contexts/AuthContext";
import { useNavigate } from 'react-router-dom';

const Logout = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const performLogout = async () => {
      console.log('Calling logout');
      await logout(); // Wait for the logout process to complete
      console.log('Navigating to /login');
      navigate('/login');
      window.location.reload(); // Refresh the page
    };

    performLogout();
  }, [logout, navigate]);

  return (
    <div>Logging out...</div>
  );
};

export default Logout;

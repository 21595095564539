import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Button,SimpleGrid,Image } from "@chakra-ui/react";
import { MdDelete } from "react-icons/md";
import API_URLS from '../../config';

const ImageGallery = ({ refresh }) => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    fetchImages();
  }, [refresh]);

  const fetchImages = async () => {
    try {
      const response = await axios.get(`${API_URLS.image}/images/`);
      setImages(response.data);
    } catch (error) {
      console.error('Error fetching images', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${API_URLS.image}/deleteimage/${id}`);
      fetchImages();
    } catch (error) {
      console.error('Error deleting image', error);
    }
  };

  return (
    <Box>
      {images.length > 0 ? (
        <SimpleGrid
          columns={{ base: 2, md: 3, lg: 4 }} 
          spacing={4} 
        >
          {images.map((image) => (
            <Box
              key={image.id}
              border="2px"
              borderColor="teal.200"
              borderRadius="lg"
              overflow="hidden"
            >
              <Image
                src={`${API_URLS.image}/images/${image.id}`}
                alt={image.filename}
                objectFit="cover"
                boxSize="150px" 
              />
              <Button
                onClick={() => handleDelete(image.id)}
                variant="ghost"
                width="full"
                mt={2}
              >
                <span style={{ marginLeft: "8px" }}>
              <MdDelete color="#f36584" fontSize={20} />
            </span>
              </Button>
            </Box>
          ))}
        </SimpleGrid>
      ) : (
        <Box>No images in the gallery</Box>
      )}
    </Box>
  );
};

export default ImageGallery;

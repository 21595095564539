import React, { useState } from "react";
import { SimpleGrid, Box, Text, Image } from "@chakra-ui/react";
import blogApp from "../assets/Auth/blogApp.png";
import image from "../assets/Auth/image.png";
import Todo from "../assets/Auth/file.png";
import file from "../assets/Auth/todo.png";
import { useNavigate } from "react-router-dom";

const SampleGrid = () => {
  const [isClicked, setIsClicked] = useState(false);
  const navigate = useNavigate();

  const handleClick = (path) => {
    setIsClicked(true);
    setTimeout(() => {
      setIsClicked(false);
      navigate(path);
    }, 200); 
  };
  
const handleClick_blog = () => handleClick("/blog");
const handleClick_tasks = () => handleClick("/tasks");
const handleClick_file = () => handleClick("/files");
const handleClick_image = () => handleClick("/images");

  return (
    <Box mt={5}>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={10} mb={8}>
        <Box
          bg="white"
          p={5}
          shadow="md"
          borderRadius="md"
          textAlign="center"
          onClick={handleClick_blog}
          cursor="pointer"
          transform={isClicked ? "scale(0.95)" : "scale(1)"}
          transition="transform 0.2s"
          _hover={{
            boxShadow: "lg",
            transform: !isClicked && "scale(1.05)",
          }}
        >
          <Box align="center" mt={5}>
            <Image
              src={blogApp}
              alt="Blog logo"
              width="150px"
              height="auto"
              mb={8}
            />
          </Box>
          <Text fontWeight={400} color="teal">
            Blog
          </Text>
        </Box>
        <Box
          bg="white"
          p={5}
          shadow="md"
          borderRadius="md"
          textAlign="center"
          onClick={handleClick_image}
          cursor="pointer"
          transform={isClicked ? "scale(0.95)" : "scale(1)"}
          transition="transform 0.2s"
          _hover={{
            boxShadow: "lg",
            transform: !isClicked && "scale(1.05)",
          }}
        >
          <Box align="center" mt={5}>
            <Image
              src={image}
              alt="Blog logo"
              width="150px"
              height="auto"
              mb={10}
            />
          </Box>
          <Text fontWeight={400} color="teal">
            Images
          </Text>
        </Box>
        <Box
          bg="white"
          p={5}
          shadow="md"
          borderRadius="md"
          textAlign="center"
          onClick={handleClick_file}
          cursor="pointer"
          transform={isClicked ? "scale(0.95)" : "scale(1)"}
          transition="transform 0.2s"
          _hover={{
            boxShadow: "lg",
            transform: !isClicked && "scale(1.05)",
          }}
        >
          <Box align="center" mt={5}>
            <Image
              src={file}
              alt="Blog logo"
              width="150px"
              height="auto"
              mb={8}
            />
          </Box>
          <Text fontWeight={400} color="teal">
            Files
          </Text>
        </Box>
        <Box
          bg="white"
          p={5}
          shadow="md"
          borderRadius="md"
          textAlign="center"
          onClick={handleClick_tasks}
          cursor="pointer"
          transform={isClicked ? "scale(0.95)" : "scale(1)"}
          transition="transform 0.2s"
          _hover={{
            boxShadow: "lg",
            transform: !isClicked && "scale(1.05)",
          }}
        >
          <Box align="center" mt={5}>
            <Image
              src={Todo}
              alt="Blog logo"
              width="150px"
              height="auto"
              mb={8}
            />
          </Box>
          <Text fontWeight={400} color="teal">
            Todo
          </Text>
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default SampleGrid;


const API_URLS = {
    auth: process.env.REACT_APP_API_URL_A,
    blog: process.env.REACT_APP_API_URL_B,
    comment: process.env.REACT_APP_API_URL_C,
    file: process.env.REACT_APP_API_URL_F,
    image: process.env.REACT_APP_API_URL_I,
    task: process.env.REACT_APP_API_URL_T 
};

  
export default API_URLS;
  
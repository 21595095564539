import React, { useState } from "react";
import { useBlog } from "../../Contexts/BlogContext";
import { MdSend } from "react-icons/md";
import { Box, Button, Text, Textarea, Divider } from "@chakra-ui/react";
import "./blog.css";

const BlogPost = ({ post,isExpanded, onToggleExpand }) => {
  const { comments, add_comment } = useBlog();
  const [commentContent, setCommentContent] = useState("");
//   const [isExpanded, setIsExpanded] = useState(false);
//   const [isCommentsVisible, setIsCommentsVisible] = useState(false);

//   const handleToggleExpand = () => {
//     setIsExpanded((prevState) => !prevState);
//     setIsCommentsVisible(prevState => !prevState);
//   };

  const truncateText = (text, length) => {
    return text.length > length ? text.substring(0, length) + "..." : text;
  };
  const postComments = comments.filter(
    (comment) => comment.post_id === post.id
  );

  const handleAddComment = () => {
    add_comment(commentContent, post.id);
    setCommentContent("");
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZoneName: "short",
    }).format(date);
  };

  return (
    <Box>
      <Box key={post.id} p={4}>
        <Text fontSize="3xl" fontWeight={200} color="#f36584" cursor="pointer">
          {post.title}
        </Text>
        <Text fontSize={10} color="gray.500" mb={2}>
          Posted on {formatDate(post.created_at)}
        </Text>
        <Text
        className={`blog-content ${isExpanded ? 'expanded' : 'collapsed'}`}
        >{isExpanded ? post.content : truncateText(post.content, 100)}</Text>
        <button onClick={onToggleExpand}>
                {isExpanded ? 'Read Less' : 'Read More'}
            </button>
        <Divider />
      </Box>

      {isExpanded && (<Box mt={4} pl={10}>
        <Text fontWeight={200} color="teal" fontSize="xl">
          Comments
        </Text>
        {postComments.map((comment) => (
          <Box
            key={comment.id}
            mt={2}
            pl={4}
            borderLeft="3px solid #f36584"
            borderRadius={5}
          >
            <Text fontSize="md">{comment.content}</Text>
            <Text fontSize="xs" color="gray.500">
              {formatDate(comment.created_at)}
            </Text>
          </Box>
        ))}
        <Box mt={4} as="form">
          <Textarea
            value={commentContent}
            placeholder="Write your comment here..."
            size="sm"
            w="50%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            onChange={(e) => setCommentContent(e.target.value)}
          />
          <Button
            colorScheme="red"
            size="sm"
            mt={2}
            _hover={{
              background: "teal.200",
            }}
            fontWeight={500}
            onClick={handleAddComment}
          >
            Comment
            <span style={{ marginLeft: "8px" }}>
              <MdSend color="white" fontSize={20} />
            </span>
          </Button>
        </Box>
      </Box>)}
    </Box>
  );
};

export default BlogPost;

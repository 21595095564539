import React, { useState } from "react";
import "./photo.css";
import ImageUpload from "./ImageUpload";
import ImageGallery from "./ImageGallery";
import addIcon from "../../assets/Auth/add.png"
import {
  Box,
  Button,
  Text,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Image,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
//  SimpleGrid,
} from "@chakra-ui/react";

const ImageApp = () => {
  const [refresh, setRefresh] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleUpload = () => {
    setRefresh(!refresh);
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      mx="auto"
      width="80%"

    >
      <Text mb="8px" color="#f36584" fontWeight={300} fontSize={30}>
        Image Gallery
      </Text>
      <Image src={addIcon}
                alt="add pic"
                width="50px"
                height="auto"
                mt={7}
                mb={2}
                onClick={onOpen}
                />
      <Text fontWeight={300}>Click icon to Add images</Text>
      
      <Divider my={10}/>
      <ImageGallery refresh={refresh} />
      <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Add your image here</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <ImageUpload onUpload={handleUpload}/>
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="teal" mr={3} onClick={onClose}>
                  Close
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
    </Box>
  );
};

export default ImageApp;

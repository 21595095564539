import React from 'react';
import { Box, Container, Stack, Text, Link, IconButton } from '@chakra-ui/react';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';

const Footer = () => {
    return (
      <Box >
        <Container as={Stack} maxW="6xl" spacing={4} mx="auto" >
          <Stack direction="row" spacing={6} justify="center">
            <Link href="https://www.facebook.com" isExternal>
              <IconButton
                icon={<FaFacebook />}
                aria-label="Facebook"
                variant="ghost"
                color="blue.500"
              />
            </Link>
            <Link href="https://www.twitter.com" isExternal>
              <IconButton
                icon={<FaTwitter />}
                aria-label="Twitter"
                variant="ghost"
                color="blue.200"
              />
            </Link>
            <Link href="https://www.instagram.com" isExternal>
              <IconButton
                icon={<FaInstagram />}
                aria-label="Instagram"
                variant="ghost"
                color='pink.400'
              />
            </Link>
            <Link href="https://www.linkedin.com" isExternal>
              <IconButton
                icon={<FaLinkedin />}
                aria-label="LinkedIn"
                variant="ghost"
                color="blue.600"
              />
            </Link>
          </Stack>
          <Text fontSize="sm" color="gray.500" textAlign="center">
            JEREMIAH © 2025. All rights reserved.
          </Text>
        </Container>
      </Box>
    );
  };

export default Footer;

import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import API_URLS from '../config';

export const BlogContext = createContext();

export const useBlog = () => {
    return useContext(BlogContext);
};

export const BlogProvider = ({ children }) => {
    const [posts, setPosts] = useState([]);
    const [comments, setComments] = useState([]);
    const tokes = localStorage.getItem('access_token');

    useEffect(() => {
        get_posts();
        get_comments();
    }, []);

    const get_posts = async () => {
        try {
            const response = await axios.get(`${API_URLS.blog}/blogposts/`);
            setPosts(response.data);
        } catch (error) {
            console.error("Failed to get posts:", error);
        }
    };

    const create_post = async (title, content) => {
        const headers ={
            accept: "application/json",
            token: `${tokes}`,
            "Content-Type": "application/json"
        };
        try {
            await axios.post(`${API_URLS.blog}/blogposts/`, { title, content }, { headers });
            console.log("Post created")
            get_posts();
        } catch (error) {
            console.error('Failed to add post', error);
        }
    };

    const get_comments = async () => {
        try {
            const response = await axios.get(`${API_URLS.comment}/comments/`);
            setComments(response.data);
        } catch (error) {
            console.error("Failed to get comments:", error);
        }
    };

    const add_comment = async (content, post_id) => {
        try {
            await axios.post(`${API_URLS.comment}/comments/`,{content,post_id,token: `${tokes}`});
            get_comments();
        } catch (error) {
            console.error('Failed to add comment', error);
        }
    };

    return (
        <BlogContext.Provider value={{ create_post, posts, get_posts, comments, get_comments, add_comment }}>
            {children}
        </BlogContext.Provider>
    );
};

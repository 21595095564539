// src/api.js
import axios from 'axios';
import API_URLS from '../../config';

//const API_URL = 'http://localhost:8003'; // Replace with your FastAPI URL
const tokes = localStorage.getItem("access_token")

const headers  = {
  accept: "application/json",
  token: `${tokes}`,
  "Content-Type": "application/json"
}
export const getTasks = async () => {
  const response = await axios.get(`${API_URLS.task}/tasks/`,{headers});
  return response.data;
};

export const createTask = async (task) => {
  const response = await axios.post(`${API_URLS.task}/tasks/`,task,{headers});
  return response.data;
};

export const updateTask = async (id,task) => {
  const response = await axios.put(`${API_URLS.task}/tasks/${id}`,{ ...task, completed: task.is_completed },{headers});
  return response.data;
};

export const deleteTask = async (id) => {
  const response = await axios.delete(`${API_URLS.task}/tasks/${id}`,{headers});
  return response.data;
};
